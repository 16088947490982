import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import Moengage from '@moengage/web-sdk';
import { GenericObject } from '@mongez/reinforcements';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MoengageService {
  constructor() {
    this.initializeMoengage();
  }

  initializeMoengage(): void {
    const moengageConfig = {
      app_id: environment.MOENGAGE_APP_ID,
      cluster: environment.MOENGAGE_DATA_CENTER,
      debug_logs: environment.MOENGAGE_DEBUG_LOGS,
    };

    Moengage.initialize(moengageConfig);
  }

  public identify(): void {
    Moengage.add_unique_user_id(user.id);
  }

  public destroySession(): void {
    Moengage.destroy_session();
  }

  public trackEvent(eventName: string, data?: GenericObject): void {
    Moengage.track_event(eventName, data);
  }
}
