/**
 * This is the source where the user get's to Taager from.
 *
 * For example, it can be Facebook, TikTok etc.
 *
 * Where can this be used? In the signup when we are sending the Channel
 * of user acquisition.
 */
export const CUSTOMER_TOUCH_POINT = 'customerTouchPoint';

/**
 * This is a referral code that we will be using to know if the user
 * got to be a taager merchant after getting a referral link from
 * another merchant for example
 */
export const MERCHANT_REFERRAL_LINK = 'merchantReferralLink';

/**
 * So, we will need to keep track of where we will be sending the user once
 * they successfully verifiy their data. Then once it meets it's purpose, we
 * make sure to clear it from store.
 */
export const MERCHANT_OPTIN_REDIRECT = 'merchantOptinRedirect';

/**
 * So, this will be used to store Customer Effort Score data, clientside.
 */
export const CUSTOMER_EFFORT_SCORE = 'customerEffortScore';

/**
 * To show the Tamara integration Popup, clientside.
 */
export const TAMARA_PREFERENCE_KEY = 'hideTamaraProfitPreference';

/**
 * To show the Email Verification on Step of Registration Utility, clientside.
 */
export const EMAIL_OTP_STATE = 'showEmailOTP';

/**
 * To allow the Email Verification skip on login, clientside.
 */
export const SKIP_EMAIL_OTP = 'skipEmailOTP';

/**
 * To show the Dynamic Pricing Popup.
 */
export const DYNAMIC_PRICING_POPUP_KEY = 'hideDynamicPricingPopup';
