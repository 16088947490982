import { Injectable } from '@angular/core';
import { mediaUrl } from '@features/shared';
import { ArticleModel, SubTopicModel, VideoModel } from 'src/app/core/domain/learning-center.model';
import { TopicEntity } from './entities/learning-center.entity';

const LEARNING_CENTER_DATA: TopicEntity[] = [
  {
    id: 'ksa-e-commerce',
    name: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.TOPIC',
        id: 'ksa-e-commerce1',
        content: {
          articles: [
            {
              id: 'ksa-e-commerce1-article1',
              title: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/ksa-ecommerce.jpeg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_1.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce.jpeg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_2.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_3.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_3.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce2.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_4.HEADER',
                  text: '',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_5.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_5.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_6.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_6.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_7.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce3.gif'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_8.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_9.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_9.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_11.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_11.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_12.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_12.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_13.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_13.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_14.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_14.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_15.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_15.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_16.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_16.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce4.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_17.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_17.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce5.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_18.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_18.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce7.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_19.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_19.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce8.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_20.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_20.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce9.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_21.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_21.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce10.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_22.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_22.TEXT',
                  img: mediaUrl('learning-center/ksa-ecommerce11.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_23.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_23.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_24.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_24.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_25.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_25.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_26.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_26.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_27.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_ECOMMERCE.SECTION_27.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'uae-start',
    name: 'LEARNING_CENTER.CONTENT.UAE_START.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.UAE_START.TOPIC',
        id: 'uae-start1',
        content: {
          articles: [
            {
              id: 'uae-start1-article1',
              title: 'LEARNING_CENTER.CONTENT.UAE_START.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/uae-start11.jpg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.UAE_START.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_1.TEXT',
                  img: mediaUrl('learning-center/uae-start11.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_2.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_2.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_3.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_3.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_4.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_4.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_5.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_5.TEXT',
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_6.TEXT',
                  img: mediaUrl('learning-center/uae-start23.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_7.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_8.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_8.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_9.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_9.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_10.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_10.TEXT',
                  img: mediaUrl('learning-center/uae-start20.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_11.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_11.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_12.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_12.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_13.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_13.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_14.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_14.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_15.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_15.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_16.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_16.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_17.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_17.TEXT',
                  img: mediaUrl('learning-center/uae-start9.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_18.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_18.TEXT',
                  img: mediaUrl('learning-center/uae-start10.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_19.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_19.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_20.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_20.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_21.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_21.TEXT',
                  img: mediaUrl('learning-center/uae-start14.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_22.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_22.TEXT',
                  img: mediaUrl('learning-center/uae-start22.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_23.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_23.TEXT',
                  img: mediaUrl('learning-center/uae-start6.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_24.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_24.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_25.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_25.TEXT',
                  img: mediaUrl('learning-center/uae-start12.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_26.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_26.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_27.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_27.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_28.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_28.TEXT',
                  img: mediaUrl('learning-center/uae-start15.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_29.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_29.TEXT',
                  img: mediaUrl('learning-center/uae-start21.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_30.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_30.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_31.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_31.TEXT',
                  img: mediaUrl('learning-center/uae-start2.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_32.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_32.TEXT',
                  img: mediaUrl('learning-center/uae-start5.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_33.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_33.TEXT',
                  img: mediaUrl('learning-center/uae-start19.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_34.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_34.TEXT',
                  img: mediaUrl('learning-center/uae-start3.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_35.TEXT',
                  img: mediaUrl('learning-center/uae-start7.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_36.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_36.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_37.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_37.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_38.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_38.TEXT',
                  img: mediaUrl('learning-center/uae-start17.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_39.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_39.TEXT',
                  img: mediaUrl('learning-center/uae-start13.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_40.TEXT',
                  img: mediaUrl('learning-center/uae-start8.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_41.TEXT',
                  img: mediaUrl('learning-center/uae-start18.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_42.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_43.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_43.TEXT',
                  img: mediaUrl('learning-center/uae-start16.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_44.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_44.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_45.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_45.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_46.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_46.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_47.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_47.TEXT',
                  img: mediaUrl('learning-center/uae-start1.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_48.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_48.TEXT',
                  img: mediaUrl('learning-center/uae-start4.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_49.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.UAE_START.SECTION_49.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'ksa-terms',
    name: 'LEARNING_CENTER.CONTENT.KSA_TERMS.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.KSA_TERMS.TOPIC',
        id: 'ksa-terms1',
        content: {
          articles: [
            {
              id: 'ksa-terms1-article1',
              title: 'LEARNING_CENTER.CONTENT.KSA_TERMS.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/ksa-terms.jpeg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.KSA_TERMS.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_1.TEXT',
                  img: mediaUrl('learning-center/ksa-terms.jpeg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_2.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_3.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_3.TEXT',
                  img: mediaUrl('learning-center/ksa-terms2.gif'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_4.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_4.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_5.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_5.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_6.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_6.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_7.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_8.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_8.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_9.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_9.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_10.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_10.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_11.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_11.TEXT',
                  img: mediaUrl('learning-center/ksa-terms4.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_12.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_12.TEXT',
                  img: mediaUrl('learning-center/ksa-terms5.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_13.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_13.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_14.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_14.TEXT',
                  img: mediaUrl('learning-center/ksa-terms6.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_15.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_TERMS.SECTION_15.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'saudi-market',
    name: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.1',
        id: 'saudi-market1',
        content: {
          articles: [
            {
              id: 'saudi-market-article1',
              title: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.1',
              thumbnailUrl: mediaUrl('learning-center/ksa-consumer-cover.svg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.ONE.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.ONE.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TWO.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TWO.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers1.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.THREE.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.THREE.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers2.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FOUR.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FOUR.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers3.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FIVE.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FIVE.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.SIX.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.SIX.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers4.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.SEVEN.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.SEVEN.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers5.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.EIGHT.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.EIGHT.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers6.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.NINE.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.NINE.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers7.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TEN.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TEN.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers8.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.ELEVEN.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.ELEVEN.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers9.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TWELVE.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.TWELVE.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers10.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.THIRTEEN.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.THIRTEEN.TEXT',
                  img: mediaUrl('learning-center/ksa-consumers11.svg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FOURTEEN.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.SAUDI_MARKET.FOURTEEN.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'ksa-steps',
    name: 'LEARNING_CENTER.CONTENT.KSA_STEPS.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.KSA_STEPS.TOPIC',
        id: 'ksa-steps1',
        content: {
          articles: [
            {
              id: 'ksa-steps1-article1',
              title: 'LEARNING_CENTER.CONTENT.KSA_STEPS.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/ksa-steps.jpeg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.KSA_STEPS.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_1.TEXT',
                  img: mediaUrl('learning-center/ksa-steps2.gif'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_2.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_3.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_3.TEXT',
                  img: mediaUrl('learning-center/ksa-steps3.gif'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_4.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_4.TEXT',
                  img: mediaUrl('learning-center/ksa-steps4.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_5.HEADER',
                  text: '',
                  img: mediaUrl('learning-center/ksa-steps5.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_6.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_6.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_7.TEXT',
                  img: mediaUrl('learning-center/ksa-steps6.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_8.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_8.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_9.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_9.TEXT',
                  img: mediaUrl('learning-center/ksa-steps7.jpeg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_10.TEXT',
                  img: mediaUrl('learning-center/ksa-steps13.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_11.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_11.TEXT',
                  img: mediaUrl('learning-center/ksa-steps8.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_12.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_12.TEXT',
                  img: mediaUrl('learning-center/ksa-steps9.jpeg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_13.TEXT',
                  img: mediaUrl('learning-center/ksa-steps10.jpeg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_14.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_15.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_15.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_16.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_16.TEXT',
                  img: mediaUrl('learning-center/ksa-steps11.jpeg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_17.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_17.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_18.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.KSA_STEPS.SECTION_18.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'youcan',
    name: 'LEARNING_CENTER.CONTENT.YOUCAN.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.YOUCAN.1',
        id: 'youcan1',
        content: {
          videos: [
            {
              id: 'youcan1-video1',
              title: 'LEARNING_CENTER.CONTENT.YOUCAN.1',
              header: 'LEARNING_CENTER.CONTENT.YOUCAN.1',
              thumbnailUrl: mediaUrl('learning-center/youcan-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.YOUCAN.2',
              videoUrl: '//www.youtube.com/embed/V-c_OMwwqmk',
              duration: 'LEARNING_CENTER.CONTENT.YOUCAN.3',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.YOUCAN.4',
        id: 'youcan2',
        content: {
          videos: [
            {
              id: 'youcan2-video1',
              title: 'LEARNING_CENTER.CONTENT.YOUCAN.4',
              header: 'LEARNING_CENTER.CONTENT.YOUCAN.4',
              thumbnailUrl: mediaUrl('learning-center/youcan-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.YOUCAN.2',
              videoUrl: '//www.youtube.com/embed/lFC15nJs_-A',
              duration: 'LEARNING_CENTER.CONTENT.YOUCAN.5',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'lead-generation',
    name: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.1',
        id: 'lead-generation1',
        content: {
          videos: [
            {
              id: 'lead-generation1-video1',
              title: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.TOPIC',
              header: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.4',
              thumbnailUrl: mediaUrl('learning-center/lead-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.1',
              videoUrl: '//www.youtube.com/embed/Ue_rsIjTV1U',
              duration: 'LEARNING_CENTER.CONTENT.LEAD_GENERATION.3',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'conversion',
    name: 'LEARNING_CENTER.CONTENT.CONVERSION.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION.1',
        id: 'conversion1',
        content: {
          videos: [
            {
              id: 'conversion1-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION.1',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION.1',
              thumbnailUrl: mediaUrl('learning-center/conversion-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION.1',
              videoUrl: '//www.youtube.com/embed/3JKNn20deWA',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION.6',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION.2',
        id: 'conversion2',
        content: {
          videos: [
            {
              id: 'conversion2-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION.2',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION.2',
              thumbnailUrl: mediaUrl('learning-center/conversion-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION.2',
              videoUrl: '//www.youtube.com/embed/qd1D-kpHQJw',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION.7',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'easy-orders',
    name: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.TITLE',
        id: 'easy-orders1',
        content: {
          videos: [
            {
              id: 'easy-orders1-video1',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.INTRO',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.INTRO',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/5N73X_uhRlU',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.INTRO_DURATION',
            },
            {
              id: 'easy-orders1-video2',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/RiuurBIPdJY',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE_DURATION',
            },
            {
              id: 'easy-orders1-video3',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.ACTIVATE',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.ACTIVATE',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/t5MfTavDMII',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.ACTIVATE_DURATION',
            },
            {
              id: 'easy-orders1-video4',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE_DOMAIN',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE_DOMAIN',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/GGu6CrekNd8',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_1.CREATE_DOMAIN_DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.TITLE',
        id: 'easy-orders2',
        content: {
          videos: [
            {
              id: 'easy-orders2-video1',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/ffWZi8JUYZU',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT_DURATION',
            },
            {
              id: 'easy-orders2-video2',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT_OPTIONS',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT_OPTIONS',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/SauBRavAaQg',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_2.IMPORT_OPTIONS_DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.TITLE',
        id: 'easy-orders3',
        content: {
          videos: [
            {
              id: 'easy-orders3-video1',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.CATEGORIES',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.CATEGORIES',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/JMDFrSNTOho',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.CATEGORIES_DURATION',
            },
            {
              id: 'easy-orders3-video2',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.PRODUCTS',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.PRODUCTS',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/EG1h39xrwzg',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.PRODUCTS_DURATION',
            },
            {
              id: 'easy-orders3-video3',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.HOME_PAGE',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.HOME_PAGE',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/bDnFo_A9caA',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.HOME_PAGE_DURATION',
            },
            {
              id: 'easy-orders3-video4',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.STORE_SETTING',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.STORE_SETTING',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/KF8iMWU080k',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_3.STORE_SETTING_DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.TITLE',
        id: 'easy-orders4',
        content: {
          videos: [
            {
              id: 'easy-orders4-video1',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.CREATE',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.CREATE',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/ZIdUGJ1TFU8',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.CREATE_DURATION',
            },
            {
              id: 'easy-orders4-video2',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.LINK',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.LINK',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/H8HgpZWi2fE',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.LINK_DURATION',
            },
            {
              id: 'easy-orders4-video3',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/vst-yuHgTfc',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE_DURATION',
            },
            {
              id: 'easy-orders4-video4',
              title: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE_2',
              header: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE_2',
              thumbnailUrl: mediaUrl('learning-center/easy-orders-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/HLoE24G9ebs',
              duration: 'LEARNING_CENTER.CONTENT.EASY_ORDERS.SUBTOPIC_4.ADVICE_2_DURATION',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'facebook',
    name: 'LEARNING_CENTER.CONTENT.FACEBOOK.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.FACEBOOK.1',
        id: 'facebook1',
        content: {
          videos: [
            {
              id: 'facebook1-video1',
              title: 'LEARNING_CENTER.CONTENT.FACEBOOK.1',
              header: 'LEARNING_CENTER.CONTENT.FACEBOOK.1',
              thumbnailUrl: mediaUrl('learning-center/facebook-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.FACEBOOK.3',
              videoUrl: '//www.youtube.com/embed/oCJ__HiTobI',
              duration: 'LEARNING_CENTER.CONTENT.FACEBOOK.4',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'shahbndar',
    name: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.SECTION_1',
        id: 'shahbndar1',
        content: {
          videos: [
            {
              id: 'shahbndar1-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.1',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.1',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/CYaNtkWKHRs',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.21',
            },
            {
              id: 'shahbndar2-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.2',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.2',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/AxvIadYi2N8',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.22',
            },
            {
              id: 'shahbndar3-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.3',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.3',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/q4_-w7Gjsgk',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.23',
            },
            {
              id: 'shahbndar4-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.4',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.4',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/q3s_fFevGw0',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.24',
            },
            {
              id: 'shahbndar5-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.5',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.5',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/XcNg3xPJ4vY',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.25',
            },
            {
              id: 'shahbndar6-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.6',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.6',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/3F5XfDsBSkU',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.26',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.SECTION_2',
        id: 'shahbndar7',
        content: {
          videos: [
            {
              id: 'shahbndar7-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.7',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.7',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/SPYKd-T1r5w',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.27',
            },
            {
              id: 'shahbndar8-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.8',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.8',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/-oNim-dJxG8',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.28',
            },
            {
              id: 'shahbndar9-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.9',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.9',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/3raECYbxyAk',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.29',
            },
            {
              id: 'shahbndar10-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.10',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.10',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/sq15hzLm2x8',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.30',
            },
            {
              id: 'shahbndar11-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.11',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.11',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/whz1sf3Z3UM',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.31',
            },
            {
              id: 'shahbndar12-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.12',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.12',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/9YmrGFLYnOM',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.32',
            },
            {
              id: 'shahbndar13-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.13',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.13',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/yDVTX7OuxrQ',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.33',
            },
            {
              id: 'shahbndar14-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.14',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.14',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/fve2K35bu80',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.34',
            },
            {
              id: 'shahbndar15-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.15',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.15',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/xKfchxybMs4',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.35',
            },

            {
              id: 'shahbndar19-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.19',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.19',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/RZumnpS915M',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.39',
            },
            {
              id: 'shahbndar20-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.20',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.20',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/zM4fZY6nFk4',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.40',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.SECTION_3',
        id: 'shahbndar20',
        content: {
          videos: [
            {
              id: 'shahbndar16-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.16',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.16',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/7D5I8Wp5RWQ',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.36',
            },
            {
              id: 'shahbndar17-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.17',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.17',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/bvadK3X0NpA',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.37',
            },
            {
              id: 'shahbndar18-video1',
              title: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.18',
              header: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.18',
              thumbnailUrl: mediaUrl('learning-center/shahbandar-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/-BsvmjovkgM',
              duration: 'LEARNING_CENTER.CONTENT.SHAHBNDAR.38',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'taager-wordpress-plugin',
    name: 'LEARNING_CENTER.CONTENT.WORDPRESS.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'beginner',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.1',
        id: 'taager-wordpress-plugin1',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin1-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.1',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.1',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/55CEE2zdFHY',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.2',
        id: 'taager-wordpress-plugin2',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin2-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.2',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.2',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/r4Rvcki0Dy0',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.3',
        id: 'taager-wordpress-plugin3',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin3-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.3',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.3',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/1JCb5HFcCI8',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.4',
        id: 'taager-wordpress-plugin4',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin4-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.4',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.4',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/yzO2KlXmOPo',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.5',
        id: 'taager-wordpress-plugin5',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin5-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.5',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.5',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/_UiNoIHD_Q4',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.6',
        id: 'taager-wordpress-plugin6',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin6-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.6',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.6',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/kKEn9kzxYQM',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.7',
        id: 'taager-wordpress-plugin7',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin7-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.7',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.7',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/PD95_uQJ32c',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.8',
        id: 'taager-wordpress-plugin8',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin8-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.8',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.8',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/L6A7qac1vaQ',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.9',
        id: 'taager-wordpress-plugin9',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin9-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.9',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.9',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/IJXl5vaIWok',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.10',
        id: 'taager-wordpress-plugin10',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin10-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.10',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.10',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/HfUV2Uc1gQo',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.WORDPRESS.11',
        id: 'taager-wordpress-plugin11',
        content: {
          videos: [
            {
              id: 'taager-wordpress-plugin11-video1',
              title: 'LEARNING_CENTER.CONTENT.WORDPRESS.11',
              header: 'LEARNING_CENTER.CONTENT.WORDPRESS.11',
              thumbnailUrl: mediaUrl('learning-center/plugin-cover.svg'),
              description: 'LEARNING_CENTER.CONTENT.WORDPRESS.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/Ix2UbC6QfIA',
              duration: 'LEARNING_CENTER.CONTENT.WORDPRESS.DURATION',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'conversion-campaign',
    name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'intermediate',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INTRO',
        id: 'conversion-campaign1',
        content: {
          videos: [
            {
              id: 'conversion-campaign1-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INTRO',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INTRO',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/tMoALR0hD-c',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TOOLS',
        id: 'conversion-campaign2',
        content: {
          videos: [
            {
              id: 'conversion-campaign2-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TOOLS',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TOOLS',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/lub7fbF8P78',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PRICING',
        id: 'conversion-campaign3',
        content: {
          videos: [
            {
              id: 'conversion-campaign3-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PRICING',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PRICING',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/NNR7pqs4-O4',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.ADDS_ACCOUNT',
        id: 'conversion-campaign4',
        content: {
          videos: [
            {
              id: 'conversion-campaign4-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.ADDS_ACCOUNT',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.ADDS_ACCOUNT',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/_r_SlW5BwlE',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PIXEL_CONNECT',
        id: 'conversion-campaign5',
        content: {
          videos: [
            {
              id: 'conversion-campaign5-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PIXEL_CONNECT',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.PIXEL_CONNECT',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/7uIZI8hQwlU',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.THREE_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.READY_TO_START',
        id: 'conversion-campaign6',
        content: {
          videos: [
            {
              id: 'conversion-campaign6-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.READY_TO_START',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.READY_TO_START',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/C4ZwCmjRAgs',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TIKTOK',
        id: 'conversion-campaign7',
        content: {
          videos: [
            {
              id: 'conversion-campaign7-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TIKTOK',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TIKTOK',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/eSKFrGiveuI',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.THREE_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.FB',
        id: 'conversion-campaign8',
        content: {
          videos: [
            {
              id: 'conversion-campaign8-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.FB',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.FB',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/zhJOFhAcKxI',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.FOUR_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TARGET_TEST',
        id: 'conversion-campaign9',
        content: {
          videos: [
            {
              id: 'conversion-campaign9-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TARGET_TEST',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TARGET_TEST',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/eBkFiODAUiY',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INVEST',
        id: 'conversion-campaign10',
        content: {
          videos: [
            {
              id: 'conversion-campaign10-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INVEST',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.INVEST',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/WOjMWNh0eoY',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.THREE_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TERMS',
        id: 'conversion-campaign11',
        content: {
          videos: [
            {
              id: 'conversion-campaign11-video1',
              title: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TERMS',
              header: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.TERMS',
              thumbnailUrl: mediaUrl('learning-center/conversion-campaign.jpg'),
              description: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.DESCRIPTION',
              videoUrl: '//www.youtube.com/embed/H8cXTbx2uKw',
              duration: 'LEARNING_CENTER.CONTENT.CONVERSION_CAMPAIGN.ONE_MINUTE',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'increase-profit',
    name: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'expert',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.TOPIC',
        id: 'increase-profit1',
        content: {
          articles: [
            {
              id: 'increase-profit1-article1',
              title: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/increase-profit2.png'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_1.TEXT',
                  img: mediaUrl('learning-center/increase-profit5.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_2.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_2.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_3.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_3.TEXT',
                  img: mediaUrl('learning-center/increase-profit6.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_4.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_4.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_5.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_5.TEXT',
                  img: mediaUrl('learning-center/increase-profit7.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_6.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_6.TEXT',
                  img: mediaUrl('learning-center/increase-profit9.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_7.TEXT',
                  img: mediaUrl('learning-center/increase-profit10.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_8.HEADER',
                  text: '',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_9.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_9.TEXT',
                  img: mediaUrl('learning-center/increase-profit8.png'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_10.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_10.TEXT',
                  img: mediaUrl('learning-center/increase-profit3.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_11.TEXT',
                  img: mediaUrl('learning-center/increase-profit4.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_12.TEXT',
                  img: mediaUrl('learning-center/increase-profit1.png'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.INCREASE_PROFIT.SECTION_13.TEXT',
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    id: 'advanced-tiktok',
    name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'expert',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TARGETING',
        id: 'advanced-tiktok1',
        content: {
          videos: [
            {
              id: 'advanced-tiktok1-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TARGETING',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TARGETING',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/034cj2iPblI?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TWELVE_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.ACTIVATE',
        id: 'advanced-tiktok2',
        content: {
          videos: [
            {
              id: 'advanced-tiktok2-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.ACTIVATE',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.ACTIVATE',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/664hsIdgteI?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TWENTY_TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.LEAD',
        id: 'advanced-tiktok3',
        content: {
          videos: [
            {
              id: 'advanced-tiktok3-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.LEAD',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.LEAD',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/Uy5qFEcfXrM?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.FOURTEEN_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.CATALOG',
        id: 'advanced-tiktok4',
        content: {
          videos: [
            {
              id: 'advanced-tiktok4-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.CATALOG',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.CATALOG',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/vbl-hqa9FZ8?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TWENTY_TWO_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.SPARK',
        id: 'advanced-tiktok5',
        content: {
          videos: [
            {
              id: 'advanced-tiktok5-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.SPARK',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.SPARK',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/aJz9D8Bw-YI?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TWENTY_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.MISTAKES',
        id: 'advanced-tiktok6',
        content: {
          videos: [
            {
              id: 'advanced-tiktok6-video1',
              title: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.MISTAKES',
              header: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.MISTAKES',
              thumbnailUrl: mediaUrl('learning-center/advanced-tiktok.jpeg'),
              description: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/WPPRB0o-9g8?list=PLmEy_hlZ7kB9xfl_Z7HuhCZBRlnGDaEFu',
              duration: 'LEARNING_CENTER.CONTENT.ADVANCED_TIKTOK.TWENTY_MINUTES',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'beginner-tiktok',
    name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'intermediate',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TARGETING',
        id: 'beginner-tiktok1',
        content: {
          videos: [
            {
              id: 'beginner-tiktok1-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TARGETING',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TARGETING',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/16cZqgyrOGA?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.ELEVEN_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.AD',
        id: 'beginner-tiktok2',
        content: {
          videos: [
            {
              id: 'beginner-tiktok2-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.AD',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.AD',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/YI3QitoYLG0?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.EIGHT_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.PIXEL',
        id: 'beginner-tiktok3',
        content: {
          videos: [
            {
              id: 'beginner-tiktok3-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.PIXEL',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.PIXEL',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/kVHq4qI4IWY?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.FIFTEEN_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SETUP',
        id: 'beginner-tiktok4',
        content: {
          videos: [
            {
              id: 'beginner-tiktok4-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SETUP',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SETUP',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/_P35eNBkeGs?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TWELVE_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.LEAD',
        id: 'beginner-tiktok5',
        content: {
          videos: [
            {
              id: 'beginner-tiktok5-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.LEAD',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.LEAD',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/NfqNIPTe6cA?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.ELEVEN_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SPARK',
        id: 'beginner-tiktok6',
        content: {
          videos: [
            {
              id: 'beginner-tiktok6-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SPARK',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SPARK',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/gTnbrCnLCR4?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.SIX_MINUTES',
            },
          ],
        },
      },
      {
        name: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TIPS',
        id: 'beginner-tiktok7',
        content: {
          videos: [
            {
              id: 'beginner-tiktok7-video1',
              title: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TIPS',
              header: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.TIPS',
              thumbnailUrl: mediaUrl('learning-center/beginner-tiktok-cover.png'),
              description: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.DESCRIPTION',
              videoUrl:
                '//www.youtube.com/embed/lJJuenWAWrY?list=PLmEy_hlZ7kB9KR5iCq6RjewFqw8AqwLJy',
              duration: 'LEARNING_CENTER.CONTENT.BEGINNER_TIKTOK.EIGHT_MINUTES',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'white-friday',
    name: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.TOPIC',
    iconUrl: mediaUrl('learning-center/folder_open.svg'),
    level: 'intermediate',
    subTopics: [
      {
        name: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.TOPIC',
        id: 'white-friday-subtopic',
        content: {
          articles: [
            {
              id: 'white-friday-article',
              title: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.TOPIC',
              thumbnailUrl: mediaUrl('learning-center/cover.jpg'),
              readingMinutes: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.DURATION',
              sections: [
                {
                  header: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION1.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION1.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION2.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION2.TEXT',
                  img: mediaUrl('learning-center/first-step.jpg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION3.TEXT',
                  img: mediaUrl('learning-center/second-step.jpg'),
                },
                {
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION4.TEXT',
                  img: mediaUrl('learning-center/third-step.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION5.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION5.TEXT',
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION6.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION6.TEXT',
                  img: mediaUrl('learning-center/taager-profits.jpg'),
                },
                {
                  header: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION7.HEADER',
                  text: 'LEARNING_CENTER.CONTENT.WHITE_FRIDAY.SECTION7.TEXT',
                  img: mediaUrl('learning-center/conclusion.jpg'),
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class LearningCenterProvider {
  getTopics(): TopicEntity[] {
    return LEARNING_CENTER_DATA;
  }

  getTopicById(topicId: string): TopicEntity {
    return LEARNING_CENTER_DATA.filter((topic) => topic.id === topicId)[0];
  }

  getSubTopicById(subTopicId: string): SubTopicModel {
    return LEARNING_CENTER_DATA.map((item) => item.subTopics)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, [])
      .filter((topic) => topic.id === subTopicId)[0];
  }

  getContentById(contentId: string): VideoModel | ArticleModel {
    return LEARNING_CENTER_DATA.map((item) => item.subTopics)
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, [])
      .map((st) => [...(st.content.articles || []), ...(st.content.videos || [])])
      .reduce((prev, curr) => {
        return [...prev, ...curr];
      }, [])
      .filter((item) => item.id === contentId)[0];
  }

  getTopicFromSubTopicId(subTopicId: string): TopicEntity {
    return this.getTopics().filter(
      (topic) => topic.subTopics.filter((subTopic) => subTopic.id === subTopicId)[0],
    )[0];
  }
}
