import { groupedTranslations } from '@mongez/localization';

groupedTranslations('currencies', {
  OMR: {
    en: 'OMR',
    ar: 'ريال عماني',
  },
  SAR: {
    en: 'SAR',
    ar: 'ريال سعودي',
  },
  AED: {
    en: 'AED',
    ar: 'درهم إماراتي',
  },
  IQD: {
    en: 'IQD',
    ar: 'دينار عراقي',
  },
  KWD: {
    en: 'KWD',
    ar: 'دينار كويتي',
  },
  EGP: {
    en: 'EGP',
    ar: 'جنيه مصري',
  },
  CTM: {
    en: 'CTM',
    ar: 'CTM',
  },
  SARShort: {
    en: 'SAR',
    ar: 'ر.س',
  },
  AEDShort: {
    en: 'AED',
    ar: 'د.إ',
  },
  IQDShort: {
    en: 'IQD',
    ar: 'د.ع',
  },
  KWDShort: {
    en: 'KWD',
    ar: 'د.ك',
  },
  EGPShort: {
    en: 'EGP',
    ar: 'ج.م',
  },
  CTMShort: {
    en: 'CTM',
    ar: 'CTM',
  },
  OMRShort: {
    en: 'OMR',
    ar: 'ر.ع',
  },
});
