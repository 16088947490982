<div (click)="trackCategoryClick()" tabindex="0">
  <a
    class="category-list-item"
    [ngClass]="{
      'category-list-item--hovered': categoryNode.key === hoveredCategoryId,
      'category-list-item--ltr': isEnglishLanguage
    }"
    [routerLink]="['/', 'products', 'category', categoryNode.key]"
  >
    <div class="category-list-item__category">
      <img
        loading="lazy"
        class="category-list-item__category-icon"
        src="{{ getCategoryImage() }}"
        alt=""
      />

      <p class="category-list-item__category-name body1--bold">
        {{
          isEnglishLanguage
            ? categoryNode.value.name.englishName
            : categoryNode.value.name.arabicName
        }}
      </p>
    </div>
    <img
      loading="lazy"
      *ngIf="categoryNode.hasChildren"
      class="category-list-item__left-chevron"
      src="assets/img/{{ isEnglishLanguage ? 'right' : 'left' }}-chevron.svg"
    />
  </a>
</div>
