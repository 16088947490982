import { Mapper } from 'src/app/core/base/mapper';
import { DynamicIncentiveProgramModel } from 'src/app/core/domain/dynamic-incentive-program.model';
import { mapToLocalizedData } from '../../shared';
import { DynamicIncentiveProgramEntity } from '../entities/dynamic-incentive-program-entity';

export class DynamicIncentiveProgramMapper extends Mapper<
  DynamicIncentiveProgramEntity,
  DynamicIncentiveProgramModel
> {
  mapFrom(param: DynamicIncentiveProgramEntity): DynamicIncentiveProgramModel {
    return {
      name: mapToLocalizedData(param.name),
      challengeResult: param.challengeResult,
      country: param.country,
      description: param.description ? mapToLocalizedData(param.description) : undefined,
      termsAndConditions: param.termsAndConditions.map((i) => mapToLocalizedData(i)),
      levels: param.levels,
      orderType: param.orderType,
      durationInHours: param.durationInHours,
      type: param.type,
      status: param.status,
      rewards: param.rewards,
      ordersCount: param.ordersCount,
      endDate: new Date(param.endDate),
      startDate: new Date(param.startDate),
      expiryDate: param.expiryDate ? new Date(param.expiryDate) : undefined,
      extraTime: new Date(param.extraTime),
      ordersProgressResult: param.ordersProgressResult,
      merchantChallengeBusinessId: param.merchantChallengeBusinessId,
      maxRewards: param.maxRewards,
      maxNumberOfOrders: param.maxNumberOfOrders,
      bucketType: param.bucketType,
      sku: param?.sku,
      productId: param?.productId,
    };
  }

  mapTo(param: DynamicIncentiveProgramModel): DynamicIncentiveProgramEntity {
    return {
      name: param.name,
      challengeResult: param.challengeResult,
      country: param.country,
      description: param.description,
      termsAndConditions: param.termsAndConditions,
      levels: param.levels,
      orderType: param.orderType,
      durationInHours: param.durationInHours,
      type: param.type,
      status: param.status,
      rewards: param.rewards,
      ordersCount: param.ordersCount,
      endDate: param.endDate.toString(),
      startDate: param.startDate.toString(),
      expiryDate: param.expiryDate?.toString(),
      extraTime: param.extraTime.toString(),
      ordersProgressResult: param.ordersProgressResult,
      merchantChallengeBusinessId: param.merchantChallengeBusinessId,
      maxRewards: param.maxRewards,
      maxNumberOfOrders: param.maxNumberOfOrders,
      bucketType: param.bucketType,
      sku: param?.sku,
      productId: param?.productId,
    };
  }
}
