import { ChangeDetectorRef, inject } from '@angular/core';
import { country } from '@features/country/data';
import { getLocalizedValue } from '@features/drivers/data';
import { getCurrentLocaleCode, localizationEvents, trans } from '@mongez/localization';
import { direction, isLTR, isRTL, type Direction } from '@presentation/shared/utils';
import { SubscribableComponent } from './subscribable.component';

export abstract class LocalizedComponent extends SubscribableComponent {
  /**
   * Change detector reference to manually trigger change detection
   */
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * Translate function to be used in the html template
   */
  public trans = trans;

  /**
   * Constructor
   */
  public constructor() {
    super();

    this.subscribe(
      localizationEvents.onChange('localeCode', () => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  public onLocaleChange(callback: () => void): void {
    this.subscribe(localizationEvents.onChange('localeCode', callback));
  }

  /**
   * Determine if current direction is RTL
   */
  public get isRTL(): boolean {
    return isRTL();
  }

  /**
   * Determine if current direction is LTR
   */
  public get isLTR(): boolean {
    return isLTR();
  }

  /**
   * Get current page direction
   */
  public get direction(): Direction {
    return direction();
  }

  /**
   * Get current locale code
   */
  public get localeCode(): string {
    return getCurrentLocaleCode();
  }

  /**
   * Get localized value from the given value based on current locale
   */
  public localizedValue(value: any): string {
    return getLocalizedValue(value);
  }

  /**
   * Get currency short name
   */
  public get currencyShortName(): string {
    return country.currencyShortName;
  }

  /**
   * Get currency name
   */
  public get currencyName(): string {
    return country.currencyName;
  }
}
