export const environment = {
  production: false,
  BACKEND_URL: "https://merchant.api.dev.taager.com/",
  BACKEND_URL_API: "https://merchant.api.dev.taager.com/api",
  CUSTOMER_ORDER_URL: "https://dev.damenkom-orders.com?id=",
  HAZELNUT_API_KEY:
    "ggW9DclMzksUloOlO5Cw6PFFX3gaD2XmSBEmNL762u4pgfv2e5w7ZNvptDOFYWAn",
  ENABLE_MAIL_SEND: true,
  HIGH_QTY: 60,
  LOW_QTY: 30,
  GOOGLE_APP_ID:
    "509244296721-bv7om12jrpctk13eq3ik1f6ct77h4599.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "346947376618392",
  MIXPANEL_PROJECT_TOKEN: "74ef9da1588a9e25f7e0feba043c4871",
  MIXPANEL_PROXY_DOMAIN: "https://business.api.dev.taager.com/",
  FRESH_CHAT_TOKEN: "f03fbaa5-d873-44f8-bf08-346bf5913bb8",
  firebase: {
    apiKey: "AIzaSyCN1nLSJYfVJaFF6b76518kAlKHhqizMRA",
    vapidKey: "",
    authDomain: "taager-dev.firebaseapp.com",
    projectId: "taager-dev",
    storageBucket: "taager-dev.appspot.com",
    messagingSenderId: "647118806839",
    appId: "1:647118806839:web:8a565d9f39aeeb964144b2",
    measurementId: "G-WSK09GSDMV",
  },
  versionCheckURL: "version.json",
  WOO_COMMERCE_RETURN_URL: "https://app.dev.taager.com/{country}/stores",
  WOO_COMMERCE_CALLBACK_URL:
    "https://stores.api.dev.taager.com/stores/{country}/woocommerce",
  zendeskSecretKey:
    "prKHpj7xISuMy3HCbTmURZRKNG9Kye6gL5Qn7lPQN9HtZ8mZufBf6notB78MwUDy5lj97v_Cy4kp7k5LSQjxPw",
  IMAGES_BUCKET_URL:
    "https://app.dev.taager.com/media/",
  ZID_CLIENT_ID: "1218",
  CUSTOMER_IO_SITE_ID: "9203f4ef4e64fe67c5f7",
  CUSTOMER_IO_ORGANIZATION_ID: "21f6953f-9243-422a-b3c0-3c69c9264b49",
  RECAPTCHA_SITE_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  RECAPTCHA_BYPASS: true,
  RECAPTCHA_BYPASS_RESPONSE:
    "03AFcWeA7Dduv8NClpf1L8eC0hWx_EYapTj-KLsPim2HwPQ9YwRvrPRxgNLFCyvZj7wzQQXujo--dxcP-eEQ5l_AgASV_eMYdbL3JF3VGT2SbbmC9WnQTglhvIMNwMxozpjWCAQ1glVMGOqbbnYDsYAA0UF2cWqHjx8pt9692D0kU4qpbWttLelgB_ryYtAm-xo-kWFF_vCIY7QPvK7kL-Gz51cD4mPC89QoJUZ9PkOWIDRieSxfjSDVj-ZPGOFQZPnc1wHAuytl7dqcTRhhjKbNqRBhrdoaAQTa2WeL84zO0Ps99JiY-8pIdRxiMOXS7cRoumDLL6dMfQ8MEDhwPzdq5wnR5OWMjoiNIoeMBACJ6xx_POsawidZgo1VNa_PM4Pe-NCVfKlbM81goGJLc_pMFZ7FULVBRXyfb6MCGCxAZpGYqbWx2YeeMHma_lrbBpKVyNzIrRSlal2Z0g6Sc6Csy16DkgeO64AUR2q4wrJfUcGrpSWnpQu5aaM7xbk2wD6LiCERZDWh4e",
  ZAPIER_WEBHOOK_URL: "https://hooks.zapier.com/hooks/catch/14335452/bv3s0kv/",
  ZID_AUTHORIZE_URL:
    "https://oauth.zid.sa/oauth/authorize?client_id=1218&redirect_uri=https://app.dev.taager.com/zid-integration&response_type=code",
  YOUCAN_AUTHORIZE_URL:
    "https://seller-area.youcan.shop/admin/oauth/authorize?client_id=11&redirect_uri=https://app.dev.taager.com/youcan-integration&response_type=code&scope=*",
  datadog: {
    applicationId: "9e26bfd8-f665-434b-9c54-b19702c54c21",
    clientToken: "pub8aa3a5da2f94c28560f59ea1c4b7dea5",
    service: "merchant-frontend",
    env: "prod",
  },
  APP_ID: "518032bd-2b92-4ca8-a75d-9b0a7cdad018",
  APP_KEY: "8daca8ee-5e49-4279-a7c9-c75230da00cf",
  Domain: "msdk.freshchat.com",
  CREATIVES_TALLY_FORM_ID: "mJzD8Y",
  COMPLAIN_SUGGEST_TALLY_FORM_ID: "woGjJe",
  MOENGAGE_APP_ID: "NUXB5KFUQJZL7XKQLDSJAULI",
  MOENGAGE_DATA_CENTER: "dc_2",
  MOENGAGE_DEBUG_LOGS: 1,
};
